import React from 'react';

const RackInfoDisplay = ({ userContext, rack, className = '' }) => {
  const getStorageAreaFromEntities = (storageAreaId) => {
    for (const entity of userContext.entities) {
      const storageArea = entity.storage_areas.find(area => area.id === storageAreaId);
      if (storageArea) {
        return storageArea;
      }
    }
    return null;
  };

  const getSortedZoneInfo = () => {
    if (!rack?.shelves || !userContext?.entities) {
      return [];
    }

    const zones = new Set(rack.shelves.map(shelf => shelf.storage_area_id));
    const zoneData = {};
    
    zones.forEach(zoneId => {
      const shelves = rack.shelves.filter(shelf => shelf.storage_area_id === zoneId);
      const zone = getStorageAreaFromEntities(zoneId);
      
      if (zone) {
        zoneData[zone.name] = shelves.map(shelf => ({
          name: shelf.name,
          qrCodes: shelf.qr_codes,
          expectedProducts: shelf.expected_products || []
        }));
      }
    });

    return Object.entries(zoneData).sort((a, b) => a[0].localeCompare(b[0]));
  };

  const renderQRCodes = (qrCodes) => {
    if (!qrCodes) return null;

    const positions = ['top_left', 'top_right', 'bottom_left', 'bottom_right'];
    const hasQRCodes = positions.some(pos => qrCodes[pos]);
    
    if (!hasQRCodes) return null;

    return (
      <div className="is-size-7">
        <p className="has-text-weight-semibold mb-1">QR Codes:</p>
        <ul>
          {positions.map(pos => {
            const code = qrCodes[pos];
            return code ? (
              <li key={pos} className="ml-2">
                {pos.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}: {code}
              </li>
            ) : null;
          })}
        </ul>
      </div>
    );
  };

  const renderExpectedProducts = (products) => {
    if (!Array.isArray(products) || products.length === 0) return null;

    return (
      <div className="is-size-7">
        <p className="has-text-weight-semibold mb-1">Expected Products:</p>
        <ul>
          {products.map(product => (
            <li key={product.id} className="ml-2">
              {product.description} ({product.name})
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className={`rack-info ${className}`}>
      <h3 className="title is-5 mb-3 has-text-centered">Rack Overview</h3>
      
      {getSortedZoneInfo().length === 0 ? (
        <div className="notification is-warning">
          <span className="icon mr-2">
            <i className="fas fa-exclamation-triangle"></i>
          </span>
          No data available
        </div>
      ) : (
        <div className="content">
          {getSortedZoneInfo().map(([zoneName, shelves]) => (
            <div key={zoneName} className="mb-4">
              <h4 className="title is-6 has-text-info">
                <span className="icon-text">
                  <span className="icon">
                    <i className="fas fa-warehouse"></i>
                  </span>
                  <span>{zoneName}</span>
                </span>
              </h4>
              <div className="pl-4">
                {shelves.map(shelf => (
                  <div key={shelf.name} className="box mb-3">
                    <h5 className="subtitle is-6 mb-2">
                      <span className="icon-text">
                        <span className="icon">
                          <i className="fas fa-layer-group"></i>
                        </span>
                        <span>{shelf.name}</span>
                      </span>
                    </h5>
                    {renderQRCodes(shelf.qrCodes)}
                    {shelf.expectedProducts && shelf.expectedProducts.length > 0 && (
                      <div className="mt-3">
                        {renderExpectedProducts(shelf.expectedProducts)}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default RackInfoDisplay;