// InventoryNavigation.js
import React from 'react';
import EntitySelection from './EntitySelection'; 
import StorageAreaSelection from './StorageAreaSelection';
import RackSelection from './RackSelection';
import InventorySession from './InventorySession';

const InventoryNavigation = ({ 
  userContext, 
  state, 
  setState, 
  startInventorySession,
  inventorySessionId  
}) => {

  const handleEntitySelect = (entity) => {
    setState({ 
      ...state, 
      selectedEntity: entity, 
      zone: null, 
      rack: null, 
      shelf: null 
    });
  };

  const handleStorageAreaSelect = (storageArea) => {
    setState({ 
      ...state, 
      zone: storageArea, 
      rack: null, 
      shelf: null 
    });
  };

  // Extraire les storage areas de l'entity sélectionnée
  const storageAreas = state.selectedEntity?.storage_areas || [];

  const handleRackSelect = (rack) => {
    setState({ ...state, rack, shelf: null });
  };

  const handleBackToStorageAreas = () => {
    setState({ ...state, zone: null, rack: null, shelf: null });
  };

  const handleBackToRacks = () => {
    setState({ ...state, rack: null, shelf: null });
  };

  const updateQuantity = (shelfId, productName, change) => {
    console.log(`Updating quantity for ${productName} on shelf ${shelfId} by ${change}`);
    setState(prevState => {
      const updatedRack = {...prevState.rack};
      const shelf = updatedRack.shelves.find(s => s.id === parseInt(shelfId));
      if (shelf) {
        const product = shelf.expected_products.find(p => p.name === productName);
        if (product) {
          product.current_quantity = (product.current_quantity || 0) + change;
        }
      }
      return {...prevState, rack: updatedRack};
    });
  };

  // Priorité 1: Sélection de l'Entity
  if (!state.selectedEntity) {
    return (
      <EntitySelection
        entities={userContext.entities}
        onSelect={handleEntitySelect}
      />
    );
  }

  // Priorité 2: Sélection de la Storage Area
  if (!state.zone) {
    return (
      <StorageAreaSelection
        storageAreas={storageAreas}
        onSelect={handleStorageAreaSelect}
      />
    );
  }

  // Priorité 3: Sélection du Rack
  if (!state.rack) {
    return (
      <RackSelection
        racks={state.zone.racks}
        onSelect={handleRackSelect}
        onBack={handleBackToStorageAreas}
      />
    );
  }

  // Priorité 4: Session d'Inventaire
  return (
    <InventorySession
      rack={state.rack}
      onBack={handleBackToRacks}
      startInventorySession={startInventorySession}
      updateQuantity={updateQuantity}
      inventorySessionId={inventorySessionId}
      userContext={userContext}
      entityId={state.selectedEntity.id}
    />
  );
};

export default InventoryNavigation;