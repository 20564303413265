// components/ShelfGrid.js
import React, { useState, useEffect, useMemo } from 'react';

const ShelfGrid = ({ shelves, activeZones }) => {
  const shelfGroups = useMemo(() => {
    if (!shelves) return {};
    
    const groups = {};
    shelves.forEach(shelf => {
      const parts = shelf.name.split('-');
      if (parts.length === 3) {
        const rack_level = parts[1];
        const zone = parts[2][0];
        
        if (!groups[rack_level]) {
          groups[rack_level] = {};
        }
        if (!groups[rack_level][zone]) {
          groups[rack_level][zone] = [];
        }
        groups[rack_level][zone].push(shelf);
      }
    });

    Object.keys(groups).forEach(level => {
      Object.keys(groups[level]).forEach(zone => {
        groups[level][zone].sort((a, b) => {
          const aNum = parseInt(a.name.split('-')[2].slice(1));
          const bNum = parseInt(b.name.split('-')[2].slice(1));
          return aNum - bNum;
        });
      });
    });

    return groups;
  }, [shelves]);

  return (
    <div className="has-background-dark px-1 py-2" style={{ 
      borderRadius: '4px',
      boxShadow: '0 2px 3px rgba(0,0,0,0.1)',
      fontSize: '0.7rem',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch'
    }}>
      {/* Titre */}
      <p className="has-text-white has-text-centered mb-1" style={{ 
        fontSize: '0.7rem',
        whiteSpace: 'nowrap'
      }}>
        Rack Zones
      </p>

      {/* Container pour les niveaux et zones */}
      <div style={{ width: '100%' }}>
        {Object.keys(shelfGroups)
          .sort((a, b) => b.localeCompare(a))
          .map(level => (
            <div key={level} style={{ 
              width: '100%',
              marginBottom: '8px'
            }}>
              {/* Label du niveau */}
              <p className="has-text-grey-lighter mb-1" style={{ 
                fontSize: '0.65rem',
                whiteSpace: 'nowrap'
              }}>
                {level}
              </p>

              {/* Zones du niveau */}
              <div style={{ width: '100%' }}>
                {Object.keys(shelfGroups[level])
                  .sort((a, b) => b.localeCompare(a))
                  .map(zone => (
                    <div key={`${level}-${zone}`} 
                         style={{ 
                           display: 'flex',
                           alignItems: 'center',
                           width: '100%',
                           gap: '4px',
                           marginBottom: '2px'
                         }}>
                      {/* Label de la zone */}
                      <span style={{ 
                        fontSize: '0.65rem',
                        color: '#888',
                        width: '12px',
                        flexShrink: 0
                      }}>
                        {zone}
                      </span>

                      {/* Grille des emplacements */}
                      <div style={{
                        display: 'grid',
                        gridTemplateColumns: `repeat(${shelfGroups[level][zone].length}, 1fr)`,
                        gap: '2px',
                        flex: 1,
                        minWidth: 0
                      }}>
                        {shelfGroups[level][zone].map(shelf => (
                          <div
                            key={shelf.id}
                            className={`
                              ${activeZones.some(az => az.shelf_id === shelf.id) ? 'has-background-success' : ''}
                            `}
                            style={{
                              aspectRatio: '1',
                              backgroundColor: activeZones.some(az => az.shelf_id === shelf.id) 
                                ? undefined 
                                : 'rgba(128, 128, 128, 0.5)',
                              borderRadius: '2px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              transition: 'all 0.2s ease',
                              minHeight: '16px',
                              minWidth: '16px'
                            }}
                            title={shelf.name}
                          >
                            <span style={{ 
                              fontSize: '0.6rem',
                              color: activeZones.some(az => az.shelf_id === shelf.id) ? '#fff' : '#ccc',
                              lineHeight: 1
                            }}>
                              {shelf.name.split('-')[2].slice(1)}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ShelfGrid;