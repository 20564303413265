// src/config/videoConfig.js
export const VIDEO_CONSTRAINTS = {
  facingMode: { exact: "environment" },
  // width: { ideal: 540 },
  // height: { ideal: 960 },
  width: { ideal: 854 },
  height: { ideal: 480 },
  // width: { ideal: 1280 },
  // height: { ideal: 720 },
  aspectRatio: { ideal: 9 / 16 },
  frameRate: { ideal: 8 },
};
  
  export const CODEC = 'h264';