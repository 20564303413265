// src/contexts/WebSocketContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { getToken } from '../services/auth';
import { useAuth } from './AuthContext';


const WebSocketContext = createContext();

// Utilisation de la variable d'environnement pour l'URL du backend
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [reconnectAttempt, setReconnectAttempt] = useState(0);
  const MAX_RECONNECT_ATTEMPTS = 5;
  const { user } = useAuth();

  const connectWebSocket = (token) => {
    if (socket) {
      socket.close();
    }

    // Utilisation de l'URL du backend pour la connexion WebSocket
    const wsUrl = BACKEND_URL.replace(/^http/, 'ws');
    const newSocket = new WebSocket(`${wsUrl}?token=${token}`);

    newSocket.onopen = () => {
      console.log('WebSocket connection established');
      setIsConnected(true);
      setReconnectAttempt(0);
      startHeartbeat(newSocket);
    };

    newSocket.onclose = (event) => {
      console.log(`WebSocket connection closed. Code: ${event.code}, Reason: ${event.reason}`);
      setIsConnected(false);
      handleReconnect(token);
    };

    newSocket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    setSocket(newSocket);
  };

  const handleReconnect = (token) => {
    if (reconnectAttempt < MAX_RECONNECT_ATTEMPTS) {
      const delay = Math.pow(2, reconnectAttempt) * 1000; // Exponential backoff
      console.log(`Attempting to reconnect (${reconnectAttempt + 1}/${MAX_RECONNECT_ATTEMPTS}) in ${delay}ms`);
      setTimeout(() => {
        setReconnectAttempt(reconnectAttempt + 1);
        connectWebSocket(token);
      }, delay);
    } else {
      console.log('Max reconnection attempts reached. Please refresh the page.');
    }
  };

  const startHeartbeat = (ws) => {
    const intervalId = setInterval(() => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({ type: 'heartbeat' }));
      }
    }, 30000); // Send heartbeat every 30 seconds

    ws.addEventListener('close', () => clearInterval(intervalId));
  };

  const sendMessage = (message) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const token = getToken();
      const userContextString = localStorage.getItem('userContext');
      const userContext = userContextString ? JSON.parse(userContextString) : null;

      let enhancedMessage = { ...message };

      if (userContext && userContext.user_id) {
        enhancedMessage.user_id = userContext.user_id;
      }

      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          if (decodedToken.sub) {
            enhancedMessage.sub = decodedToken.sub;
          }
        } catch (error) {
          console.error('Error decoding JWT token:', error);
        }
      }

      socket.send(JSON.stringify(enhancedMessage));
    } else {
      console.error('WebSocket is not connected. Cannot send message.');
    }
  };

  useEffect(() => {
    if (socket) {
      const handleMessage = (event) => {
        const data = JSON.parse(event.data);
        console.log("Received message:", data);
        switch(data.type) {
          // ... autres cas ...
          case 'heartbeat_response':
            // Vous pouvez choisir de ne rien faire ici, ou mettre à jour un état si nécessaire
            console.log("Heartbeat response received:", data.timestamp);
            break;
          default:
            console.log('Unhandled message type:', data.type);
        }
      };
  
      socket.addEventListener('message', handleMessage);
  
      return () => {
        socket.removeEventListener('message', handleMessage);
      };
    }
  }, [socket]);

  return (
    <WebSocketContext.Provider value={{ socket, isConnected, connectWebSocket, sendMessage }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);