// InventorySession.js

// ======================================================
// IMPORTS
// ======================================================
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import Peer from 'simple-peer';
import { useWebSocket } from '../contexts/WebSocketContext';
import { useAuth } from '../contexts/AuthContext';
import { useInventory } from '../contexts/InventoryContext';
import { VIDEO_CONSTRAINTS, CODEC } from '../config/videoConfig';
import { jwtDecode } from 'jwt-decode';
import InfoButton from './InfoButton';
import RackInfoDisplay from './RackInfoDisplay';
import MissingProductModal from './MissingProductModal';
import ShelfGrid from './ShelfGrid';

// ======================================================
// COMPONENT INTERFACE
// ======================================================
/**
 * InventorySession Component
 * Manages real-time inventory with video stream and quantity tracking
 * 
 * @param {Object} props
 * @param {Object} props.rack - Rack information including shelves and products
 * @param {Function} props.onBack - Callback function for navigation
 * @param {Function} props.updateQuantity - Callback function for quantity updates
 * @param {Object} props.userContext - User context information
 */
const InventorySession = ({ 
  rack, 
  onBack, 
  updateQuantity,
  userContext,
}) => {
  // ======================================================
  // CONTEXTS
  // ======================================================
  const { socket, sendMessage } = useWebSocket();
  const { user } = useAuth();
  const { 
    inventorySessionId, 
    setInventorySessionId, 
    lastActiveSessionId, 
    setLastActiveSessionId 
  } = useInventory();

  // ======================================================
  // REFS
  // ======================================================
  const streamRef = useRef(null);
  const videoRef = useRef(null);

  // ======================================================
  // STATES
  // ======================================================
  // Session States
  const [isSessionActive, setIsSessionActive] = useState(false);
  const [inventoryStarted, setInventoryStarted] = useState(false);
  const [isVideoActive, setIsVideoActive] = useState(false);
  const [shouldShowVideo, setShouldShowVideo] = useState(true);

  // Video States
  const [peer, setPeer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [videoReady, setVideoReady] = useState(false);
  const [iceConnected, setIceConnected] = useState(false);

  // Torch States
  const [isTorchOn, setIsTorchOn] = useState(false);
  const [torchCapability, setTorchCapability] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  // UI States
  const [showHeader, setShowHeader] = useState(true);
  const [quantities, setQuantities] = useState({});
  const [updatedProducts, setUpdatedProducts] = useState({});
  const [productUpdateTimestamps, setProductUpdateTimestamps] = useState({});
  const [shelfUpdateTimestamps, setShelfUpdateTimestamps] = useState({});
  const [sortOption, setSortOption] = useState('lastUpdate');

  // états pour le modal de produits manquants
  const [showMissingProductModal, setShowMissingProductModal] = useState(false);
  const [selectedShelfForMissing, setSelectedShelfForMissing] = useState(null);
  const [missingProductResponse, setMissingProductResponse] = useState(null);

  const [activeZones, setActiveZones] = useState([]);

  // ======================================================
  // UTILITY FUNCTIONS
  // ======================================================
  /**
   * Truncates text to specified length
   */
  const truncate = (text, length = 30) => {
    return text.length > length ? text.substring(0, length) + '...' : text;
  };

  /**
   * Checks if a stored session is valid
   */
  const checkStoredSession = useCallback(async (sessionId) => {
    return new Promise((resolve) => {
      const messageHandler = (event) => {
        const data = JSON.parse(event.data);
        if (['session_valid', 'session_not_found', 'session_expired'].includes(data.type)) {
          socket.removeEventListener('message', messageHandler);
          resolve(data.type === 'session_valid');
        }
      };

      socket.addEventListener('message', messageHandler);
      sendMessage({
        type: 'check_session',
        inventory_session_id: sessionId
      });

      setTimeout(() => {
        socket.removeEventListener('message', messageHandler);
        resolve(false);
      }, 5000);
    });
  }, [socket, sendMessage]);

  // ======================================================
  // VIDEO STREAM MANAGEMENT
  // ======================================================
  /**
   * Initializes and starts the video stream
   */
  const startVideoStream = useCallback(async (sessionId, torchState) => {
    console.log("Starting video stream with session ID:", sessionId);
    setIsLoading(true);
    setIceConnected(false);
  
    try {
      // Configure video constraints
      const constraints = {
        video: {
          ...VIDEO_CONSTRAINTS,
          advanced: torchState ? [{ torch: true }] : []
        },
        audio: false
      };

      // Get media stream
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      streamRef.current = stream;

      // Check device capabilities
      const videoTrack = stream.getVideoTracks()[0];
      const capabilities = videoTrack.getCapabilities();
      console.log("Video capabilities:", capabilities);

      // Set torch capability
      if (capabilities.torch) {
        setTorchCapability(true);
        setIsTorchOn(torchState);
      } else {
        setTorchCapability(false);
        setIsTorchOn(false);
      }

      // Initialize peer connection
      const newPeer = new Peer({
        initiator: true,
        trickle: false,
        stream: stream,
        config: { 
          iceServers: [
            { urls: 'stun:stun.l.google.com:19302' },
            { urls: 'stun:stun1.l.google.com:19302' },
            { urls: 'stun:stun2.l.google.com:19302' },
            { urls: 'stun:stun3.l.google.com:19302' },
            { urls: 'stun:stun4.l.google.com:19302' }
          ] 
        },

        sdpTransform: (sdp) => {
          // Optimiser les paramètres SDP
          sdp = sdp.replace('useinbandfec=1', 'useinbandfec=1; stereo=0; maxaveragebitrate=128000');
          return sdp;
        }

      });

      // Set up peer event handlers
      newPeer.on('signal', data => {
        console.log("Peer signaling:", data);
        if (data.type === 'offer') {
          console.log("Sending offer to backend");
          if (sessionId) {
            console.log("Using inventory session ID:", sessionId);
            sendMessage({
              type: 'offer',
              sdp: data.sdp,
              inventory_session_id: sessionId
            });
          } else {
            console.error('No inventory session ID available');
            setIsLoading(false);
          }
        }
      });

      newPeer.on('connect', () => {
        console.log("Peer connection established");
      });

      newPeer.on('stream', stream => {
        // console.log("Received stream from backend", stream);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;

          // Utiliser le mode "low-latency"
          videoRef.current.playsinline = true;
          videoRef.current.muted = true;
          videoRef.current.autoplay = true;

          // Optimiser la mise en mémoire tampon
          videoRef.current.style.transform = 'translateZ(0)';
          videoRef.current.style.backfaceVisibility = 'hidden';

          videoRef.current.onloadedmetadata = () => {
            console.log("Video metadata loaded");
            const playPromise = videoRef.current.play();
            if (playPromise !== undefined) {
              playPromise.then(() => {
                console.log("Video playing");
                setVideoReady(true);
                if (iceConnected) {
                  setIsLoading(false);
                }
              }).catch(e => {
                console.error('Error playing video:', e);
              });
            }
          };
        }
      });

      // Handle ICE connection state changes
      newPeer._pc.oniceconnectionstatechange = () => {
        if (newPeer._pc.iceConnectionState === 'connected' || 
            newPeer._pc.iceConnectionState === 'completed') {
          console.log('ICE connection established');
          setIceConnected(true);
          if (videoReady) {
            setIsLoading(false);
          }
        }
      };

      // Handle errors
      newPeer.on('error', err => {
        console.error('Peer error:', err);
        setIsLoading(false);
        alert('Error in video connection. Please try again.');
      });

      newPeer.on('close', () => {
        console.log("Peer connection closed");
        setIsLoading(false);
        setVideoReady(false);
      });

      setPeer(newPeer);

    } catch (error) {
      console.error('Error starting video stream:', error);
      alert('Error accessing the camera. Please check your device settings.');
      setIsLoading(false);
    }
  }, [sendMessage, iceConnected, videoReady]);

  // ======================================================
  // INVENTORY MANAGEMENT
  // ======================================================
  /**
   * Handles quantity changes for products
   */
  const handleQuantityChange = (shelfId, productName, change) => {
    const key = `${shelfId}-${productName}`;
    
    setQuantities(prev => {
      const newQuantity = Math.max(0, (prev[key] || 0) + change);
      console.log(`Updating quantity for ${productName} on shelf ${shelfId}: ${prev[key]} -> ${newQuantity}`);
      
      // Send update to backend
      if (socket && socket.readyState === WebSocket.OPEN) {
        const sessionIdToUse = isSessionActive ? inventorySessionId : lastActiveSessionId;

        const message = {
          type: 'adjusted_quantity',
          data: {
            shelf_id: shelfId.toString(),
            product_name: productName,
            adjusted_quantity: newQuantity.toString(),
            adjustment_reason: change > 0 ? "User increased quantity" : "User decreased quantity",
            inventory_session_id: sessionIdToUse
          }
        };
  
        console.log('Sending adjusted_quantity message to backend:', message);
        sendMessage(message);
      }
  
      return {
        ...prev,
        [key]: newQuantity
      };
    });
  };

  /**
   * Sends the complete inventory update to the backend
   */
  const sendInventoryUpdate = () => {
    const sessionIdToUse = isSessionActive ? inventorySessionId : lastActiveSessionId;

    if (!sessionIdToUse) {
      console.error('No inventory session ID available');
      alert('Error: No active inventory session. Please start a new session.');
      return;
    }

    if (socket && socket.readyState === WebSocket.OPEN) {
      let userId = userContext?.user_id;
      if (!userId) {
        console.error('User ID not found in userContext');
        alert('User ID not found. Cannot send inventory update.');
        return;
      }

      const inventoryData = [];
      rack.shelves.forEach(shelf => {
        shelf.expected_products.forEach(product => {
          const key = `${shelf.id}-${product.name}`;
          const quantity = quantities[key] || 0;
          inventoryData.push({
            shelf_id: shelf.id,
            product_name: product.name,
            product_id: product.id,
            quantity: quantity.toString(),
            entity_id: shelf.entity_id,
            storage_area_id: shelf.storage_area_id,
            rack_id: rack.id,
            updated_by: userId,
          });
        });
      });

      const message = {
        type: 'update_stock',
        user_id: userId,
        inventory_data: inventoryData,
        inventory_session_id: sessionIdToUse
      };

      console.log("Sending inventory update:", message);
      sendMessage(message);
    }
  };

// Gestionnaire pour les produits manquants
const handleMissingProductSubmit = async (data) => {
  const sessionIdToUse = isSessionActive ? inventorySessionId : lastActiveSessionId;

  if (sessionIdToUse && socket && socket.readyState === WebSocket.OPEN) {
    // Récupérer les IDs à partir de la première étagère
    const firstShelf = rack.shelves[0];
    const entityId = firstShelf?.entity_id;
    const storageAreaId = firstShelf?.storage_area_id;

    // Log pour debug
    console.log('Retrieved IDs from shelf:', {
      entityId,
      storageAreaId,
      shelfInfo: firstShelf
    });

    if (!entityId || !storageAreaId) {
      console.error('Missing required rack information:', rack);
      alert('Error: Missing required rack information');
      return;
    }

    const message = {
      type: 'missing_product',
      data: {
        shelf_id: data.shelf_id,
        product_id: data.product_id, 
        product_description: data.product_description,
        missing_quantity: data.missing_quantity,
        comment: data.comment || '',
        timestamp: data.timestamp,
        user_id: data.user_id,
        user_name: data.user_name,
        inventory_session_id: sessionIdToUse,
        rack_id: rack.id,
        entity_id: entityId,
        storage_area_id: storageAreaId
      }
    };

    console.log('Sending missing_product message:', message);
    sendMessage(message);
  } else {
    console.error('Cannot send missing product report: no active session or socket connection');
    alert('Error: Unable to submit missing product report. Please check your connection and try again.');
  }
};

  // Fonction utilitaire pour ajouter le bouton de déclaration sur une étagère
  const renderShelfMissingButton = (shelf) => (
    <button 
      className="button is-small is-warning is-light ml-2"
      onClick={(e) => {
        e.stopPropagation();
        setSelectedShelfForMissing(shelf.id);
        setShowMissingProductModal(true);
      }}
      title="Déclarer un produit manquant dans cette zone"
    >
      <span className="icon">
        <i className="fas fa-exclamation-triangle"></i>
      </span>
    </button>
  );

  // ======================================================
  // SESSION HANDLERS
  // ======================================================
  /**
   * Handles starting the inventory session
   */
  const handleStartInventory = async () => {
    console.log("handleStartInventory called");
    if (!user || !user.token) {
      console.error('User is not authenticated. Cannot start inventory session.');
      return;
    }

    // Clear previous quantities only if starting a new session
    if (!lastActiveSessionId) {
      setQuantities({});
      localStorage.removeItem('inventoryQuantities');
    }

    setIsVideoActive(true);
    setShouldShowVideo(true);
    await actualHandleStartInventory(isTorchOn);
  };

  /**
   * Handles the actual process of starting the inventory session
   */
  const actualHandleStartInventory = useCallback(async (torchState) => {
    console.log("actualHandleStartInventory called");
    if (!user || !user.token) {
      console.error('User is not authenticated. Cannot start inventory session.');
      return;
    }
  
    setIsLoading(true);

    try {
      // Check for existing session
      const storedId = localStorage.getItem('inventorySessionId');
      if (storedId) {
        const isValid = await checkStoredSession(storedId);
        if (isValid) {
          console.log("Using stored valid session:", storedId);
          setInventorySessionId(storedId);
          setInventoryStarted(true);
          await startVideoStream(storedId, torchState);
          setIsLoading(false);
          return;
        } else {
          localStorage.removeItem('inventorySessionId');
        }
      }
  
      // Start new session
      const decodedToken = jwtDecode(user.token);
      const sub = decodedToken.sub;
      
      if (!userContext?.user_id) {
        console.error('User ID not found in userContext');
        alert('User ID not found. Cannot start inventory session.');
        setIsLoading(false);
        return;
      }

      // Request new session from backend
      const newSessionId = await new Promise((resolve, reject) => {
        sendMessage({
          type: 'start_inventory_session',
          sub: sub,
          user_id: userContext.user_id,
          width: VIDEO_CONSTRAINTS.width.ideal,
          height: VIDEO_CONSTRAINTS.height.ideal,
          user_agent: navigator.userAgent,
          codec: CODEC,
          rack_id: rack.id 
        });
  
        const messageHandler = (event) => {
          const data = JSON.parse(event.data);
          if (data.type === 'start_inventory_session_response') {
            socket.removeEventListener('message', messageHandler);
            resolve(data.inventory_session_id);
          } else if (data.type === 'start_inventory_session_error') {
            socket.removeEventListener('message', messageHandler);
            reject(new Error(data.message));
          }
        };
  
        socket.addEventListener('message', messageHandler);
      });

      console.log("New inventory session created with ID:", newSessionId);
      setInventorySessionId(newSessionId);
      localStorage.setItem('inventorySessionId', newSessionId);
      localStorage.setItem('rackId', rack.id);
  
      await new Promise(resolve => setTimeout(resolve, 0));

      setInventoryStarted(true);
      
      // Clean up old peer if exists
      if (peer) {
        peer.destroy();
        setPeer(null);
      }
  
      await startVideoStream(newSessionId, torchState);
      setInventoryStarted(true);
    } catch (error) {
      console.error('Error starting inventory session:', error);
      setIsLoading(false);
      setInventoryStarted(false);
      alert('Failed to start inventory session: ' + error.message);
    }
  }, [user, sendMessage, startVideoStream, setInventorySessionId, rack, checkStoredSession, userContext, socket, peer]);

  /**
   * Handles stopping the inventory session
   */
  const handleStopInventory = async () => {
      try {
          console.log("Stopping inventory session");
          setIsSessionActive(false);
          setIsVideoActive(false);
          setShouldShowVideo(false);

          // Cleanup vidéo et torch
          if (isTorchOn) {
              await turnOffTorch();
              setIsTorchOn(false);
          }

          if (peer) {
              peer.destroy();
              setPeer(null);
          }
          
          if (streamRef.current) {
              streamRef.current.getTracks().forEach(track => track.stop());
              streamRef.current = null;
          }

          if (videoRef.current) {
              videoRef.current.srcObject = null;
          }

          setVideoReady(false);
          setIsLoading(false);
          
          // Envoyer message de fin de session
          if (inventorySessionId && socket && socket.readyState === WebSocket.OPEN) {
              await new Promise((resolve, reject) => {
                  const timeout = setTimeout(() => {
                      reject(new Error('End session timeout'));
                  }, 5000);

                  const handleResponse = (event) => {
                      const data = JSON.parse(event.data);
                      if (data.type === 'end_inventory_session_response') {
                          clearTimeout(timeout);
                          socket.removeEventListener('message', handleResponse);
                          resolve(data);
                      }
                  };

                  socket.addEventListener('message', handleResponse);

                  sendMessage({
                      type: 'end_inventory_session',
                      inventory_session_id: inventorySessionId
                  });
              });
          }

      } catch (error) {
          console.error('Error stopping inventory:', error);
      } finally {
          // Cleanup final
          setInventorySessionId(null);
          setInventoryStarted(false);
          setLastActiveSessionId(inventorySessionId);
          
          // Fermeture propre du WebSocket si nécessaire
          if (socket && socket.readyState === WebSocket.OPEN) {
              socket.close(1000, "Session ended normally");
          }
      }
  };

  // ======================================================
  // TORCH CONTROL FUNCTIONS
  // ======================================================
  /**
   * Toggles the torch/flashlight state
   */
  const toggleTorch = async () => {
    if (!inventoryStarted || !streamRef.current) return;

    setIsTorchOn(prevState => !prevState);

    try {
      const track = streamRef.current.getVideoTracks()[0];
      const capabilities = track.getCapabilities();

      if (!capabilities.torch) {
        console.log("Torch is not supported on this device");
        return;
      }

      if (isIOS) {
        // iOS specific handling
        const currentConstraints = track.getConstraints();
        await track.stop();
        const newStream = await navigator.mediaDevices.getUserMedia({
          video: {
            ...currentConstraints,
            advanced: [{ torch: !isTorchOn }]
          }
        });
        streamRef.current = newStream;
        if (videoRef.current) {
          videoRef.current.srcObject = newStream;
        }
      } else {
        // Standard handling for other devices
        await track.applyConstraints({
          advanced: [{ torch: !isTorchOn }]
        });
      }
    } catch (err) {
      console.error("Error toggling torch:", err);
      setIsTorchOn(false);
    }
  };

  /**
   * Turns off the torch/flashlight
   */
  const turnOffTorch = async () => {
    if (!streamRef.current || !torchCapability) return;

    try {
      if (isIOS) {
        // iOS specific cleanup
        streamRef.current.getVideoTracks().forEach(track => track.stop());
      } else {
        // Standard cleanup for other devices
        const track = streamRef.current.getVideoTracks()[0];
        await track.applyConstraints({
          advanced: [{ torch: false }]
        });
      }
      setIsTorchOn(false);
    } catch (err) {
      console.error("Error turning off torch:", err);
    }
  };

  // ======================================================
  // EFFECTS
  // ======================================================
  
  // Load saved quantities on mount
  useEffect(() => {
    const savedQuantities = localStorage.getItem('inventoryQuantities');
    if (savedQuantities) {
      setQuantities(JSON.parse(savedQuantities));
    }
  }, []);

  // Save quantities when they change
  useEffect(() => {
    if (Object.keys(quantities).length > 0) {
      localStorage.setItem('inventoryQuantities', JSON.stringify(quantities));
    }
  }, [quantities]);

  // Initialize rack data
  useEffect(() => {
    if (rack && rack.shelves) {
      console.log("Rack:", rack);
      console.log("Shelves:", rack.shelves);
    } else {
      console.error("Rack or shelves are undefined");
    }
  }, [rack]);

  // Cleanup peer on unmount
  useEffect(() => {
    return () => {
      if (peer) {
        peer.destroy();
      }
    };
  }, [peer]);

  // Handle header visibility
  useEffect(() => {
    const headerSection = document.querySelector('.section.has-background-white');
    if (headerSection) {
      headerSection.classList.add('is-hidden');
    }

    return () => {
      if (headerSection) {
        headerSection.classList.remove('is-hidden');
      }
    };
  }, [rack, inventoryStarted]);

  // WebSocket message handler
  useEffect(() => {
    if (!socket) return;

    const handleMessage = (event) => {
      const data = JSON.parse(event.data);
      console.log("Received message:", data);

      switch(data.type) {
        case 'session_valid':
        case 'session_not_found':
        case 'session_expired':
          // Handled in checkStoredSession
          break;

          case 'answer':
            console.log("Received answer from backend", data);
            if (peer) {
              try {
                peer.signal(data);
              } catch (error) {
                console.error("Error signaling peer with answer:", error);
              }
            } else {
              console.warn("Received answer but peer is not initialized");
            }
            break;

        case 'start_inventory_session_response':
          console.log('Received inventory session ID:', data.inventory_session_id);
          setInventorySessionId(data.inventory_session_id);
          localStorage.setItem('inventorySessionId', data.inventory_session_id);
          break;

        case 'end_inventory_session_response':
          if (data.status === 'success') {
            console.log('Inventory session ended successfully');
            localStorage.removeItem('inventorySessionId');
            setInventorySessionId(null);
            setInventoryStarted(false);
          } else {
            console.error('Failed to end inventory session:', data.message);
            alert('Failed to end inventory session: ' + data.message);
          }
          break;

        case 'inventory':
          try {
            if (data.data) {
              Object.entries(data.data).forEach(([shelfId, shelfData]) => {
                if (shelfData.products && typeof shelfData.products === 'object') {
                  let shelfUpdated = false;
                  Object.entries(shelfData.products).forEach(([productName, productData]) => {
                    const key = `${shelfId}-${productName}`;
                    setQuantities(prev => {
                      const oldCount = prev[key] || 0;
                      const newCount = parseInt(productData.count, 10);
                      if (oldCount !== newCount) {
                        setUpdatedProducts(prevUpdated => ({
                          ...prevUpdated,
                          [key]: true
                        }));
        
                        setTimeout(() => {
                          setUpdatedProducts(prevUpdated => {
                            const { [key]: _, ...rest } = prevUpdated;
                            return rest;
                          });
                        }, 500);

                        setProductUpdateTimestamps(prevTimestamps => ({
                          ...prevTimestamps,
                          [key]: Date.now()
                        }));

                        setShelfUpdateTimestamps(prevTimestamps => ({
                          ...prevTimestamps,
                          [shelfId]: Date.now()
                        }));

                        if (newCount > oldCount) {
                          shelfUpdated = true;
                        }
                      }
                      return {
                        ...prev,
                        [key]: newCount
                      };
                    });
                  });

                  if (shelfUpdated) {
                    setShelfUpdateTimestamps(prevTimestamps => ({
                      ...prevTimestamps,
                      [shelfId]: Date.now()
                    }));
                  }
                }
              });
            }
          } catch (error) {
            console.error('Error processing inventory data:', error);
          }
          break;

        case 'update_stock_response':
          console.log('Inventory update acknowledged by backend');
          alert('Inventory update sent successfully.');
          setInventoryStarted(false);
          setQuantities({});
          setShelfUpdateTimestamps({});
          setProductUpdateTimestamps({});
          setUpdatedProducts({});
          onBack();
          break;

        case 'update_stock_error':
          console.error('Error updating inventory:', data.message);
          alert('Error updating inventory: ' + data.message);
          break;

        case 'missing_product_response':
          console.log('Missing product response:', data);
          setMissingProductResponse({
            type: data.status === 'success' ? 'success' : 'error',
            message: data.message
          });
          
          // Réinitialiser après un délai
          setTimeout(() => {
            setMissingProductResponse(null);
          }, 3000);
          break;

          case 'active_zones':
            console.log('Received active zones update:', data.zones);
            setActiveZones(data.zones);
            break;

        default:
          console.log('Unhandled message type:', data.type);
      }
    };

    socket.addEventListener('message', handleMessage);
    return () => socket.removeEventListener('message', handleMessage);
  }, [socket, setInventorySessionId, peer, onBack]);

  // Detect iOS device
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    setIsIOS(/iphone|ipad|ipod/.test(userAgent));
  }, []);

  // Set active session ID
  useEffect(() => {
    if (inventorySessionId) {
      setIsSessionActive(true);
      setLastActiveSessionId(inventorySessionId);
    }
  }, [inventorySessionId, setLastActiveSessionId]);

  // ======================================================
  // MEMOIZED VALUES AND CALLBACKS
  // ======================================================
  
  /**
   * Memoized sorted shelves calculation
   */
  const sortedShelves = useMemo(() => {
    if (!rack || !rack.shelves) return [];

    // Filter shelves with products having quantities > 0
    const filteredShelves = rack.shelves.filter(shelf => 
      shelf.expected_products.some(product => (quantities[`${shelf.id}-${product.name}`] || 0) > 0)
    );

    const shelvesCopy = [...filteredShelves];

    switch (sortOption) {
      case 'lastUpdate':
        return shelvesCopy
          .map(shelf => ({
            ...shelf,
            sortValue: shelfUpdateTimestamps[shelf.id] || 0
          }))
          .sort((a, b) => b.sortValue - a.sortValue);
      
      case 'alphabetical':
        return shelvesCopy.sort((a, b) => a.name.localeCompare(b.name));
      
      case 'objectCount':
        return shelvesCopy
          .map(shelf => ({
            ...shelf,
            sortValue: shelf.expected_products.reduce((sum, p) => {
              const key = `${shelf.id}-${p.name}`;
              return sum + (quantities[key] || 0);
            }, 0)
          }))
          .sort((a, b) => b.sortValue - a.sortValue);
      
      default:
        return shelvesCopy;
    }
  }, [rack, sortOption, shelfUpdateTimestamps, quantities]);

  /**
   * Memoized product sorting function
   */
  const getSortedProducts = useCallback((shelf) => {
    if (!shelf.expected_products) return [];

    // Filter products with quantity > 0
    const filteredProducts = shelf.expected_products.filter(product => 
      (quantities[`${shelf.id}-${product.name}`] || 0) > 0
    );

    const productsCopy = [...filteredProducts];

    switch (sortOption) {
      case 'lastUpdate':
        return productsCopy
          .map(product => ({
            ...product,
            sortValue: productUpdateTimestamps[`${shelf.id}-${product.name}`] || 0
          }))
          .sort((a, b) => b.sortValue - a.sortValue);
      
      case 'alphabetical':
        return productsCopy.sort((a, b) => a.name.localeCompare(b.name));
      
      case 'objectCount':
        return productsCopy.sort((a, b) => {
          const countA = quantities[`${shelf.id}-${a.name}`] || 0;
          const countB = quantities[`${shelf.id}-${b.name}`] || 0;
          return countB - countA;
        });
      
      default:
        return productsCopy;
    }
  }, [sortOption, productUpdateTimestamps, quantities]);

  // ======================================================
  // RENDER
  // ======================================================
  return (
    <div className="inventory-session">
      {/* Header Section */}
      {showHeader && (
        <div className="inventory-header columns is-mobile is-gapless mb-4">
          <div className="column is-narrow">
            <button onClick={onBack} className="button is-small is-light">
              <span className="icon">
                <i className="fas fa-chevron-left"></i>
              </span>
            </button>
          </div>
          <div className="column">
            <h2 className="title is-4 has-text-centered mb-0">{rack.name} Inventory</h2>
          </div>
        </div>
      )}

      {/* Video Container */}
      {shouldShowVideo && (
        <div id="videoContainer" className={isVideoActive ? '' : 'empty-video-container'}>

          {/* Grille des étagères - va se positionner dans l'espace noir à gauche */}
          <div 
            style={{
              position: 'absolute',
              left: '1%',
              top: '50%',
              transform: 'translateY(-50%)',
              width: '12%',         // Réduit de 15% à 12%
              zIndex: 3,
              minWidth: '120px',    // Réduit de 150px à 120px
              display: 'flex'
            }}
          >
            <div style={{ width: '100%' }}>
              <ShelfGrid 
                shelves={rack.shelves} 
                activeZones={activeZones}
              />
            </div>
          </div>

          {isLoading && (
            <div className="spinner-container">
              <svg className="pl" viewBox="0 0 176 160" width="176px" height="160px" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <linearGradient id="pl-grad" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor="hsl(33,90%,55%)" />
                    <stop offset="30%" stopColor="hsl(33,90%,55%)" />
                    <stop offset="100%" stopColor="hsl(3,90%,55%)" />
                  </linearGradient>
                </defs>
                <g fill="none" strokeWidth="16" strokeLinecap="round">
                  <circle className="pl__ring" r="56" cx="88" cy="96" stroke="hsla(0,10%,10%,0.1)" />
                  <path className="pl__worm1" d="M144,96A56,56,0,0,1,32,96" stroke="url(#pl-grad)" strokeDasharray="43.98 307.87" />
                  <path className="pl__worm2" d="M32,136V96s-.275-25.725,14-40" stroke="hsl(33,90%,55%)" strokeDasharray="0 40 0 44" strokeDashoffset="0.001" visibility="hidden" />
                  <path className="pl__worm3" d="M144,136V96s.275-25.725-14-40" stroke="hsl(33,90%,55%)" strokeDasharray="0 40 0 44" strokeDashoffset="0.001" visibility="hidden" />
                </g>
              </svg>
            </div>
          )}
          <video 
            id="remoteVideo" 
            ref={videoRef} 
            className={videoReady ? '' : 'is-hidden'} 
            autoPlay 
            playsInline 
            muted 
          />
        </div>
      )}

      {/* Control Buttons */}
      <div className="buttons are-small is-centered mb-4">
        {/* Sort Buttons */}
        <button 
          className={`button is-small ${sortOption === 'alphabetical' ? 'is-info' : 'is-light'}`} 
          onClick={() => setSortOption('alphabetical')}
        >
          <span className="icon">
            <i className="fas fa-sort-alpha-down"></i>
          </span>
          <span>A-Z</span>
        </button>
        
        <button 
          className={`button is-small ${sortOption === 'objectCount' ? 'is-info' : 'is-light'}`} 
          onClick={() => setSortOption('objectCount')}
        >
          <span className="icon">
            <i className="fas fa-sort-numeric-down"></i>
          </span>
          <span>Qty</span>
        </button>

        <button 
          className={`button is-small ${sortOption === 'lastUpdate' ? 'is-info' : 'is-light'}`} 
          onClick={() => setSortOption('lastUpdate')}
        >
          <span className="icon">
            <i className="fas fa-clock"></i>
          </span>
          <span>Last</span>
        </button>

        {/* Info Button */}
        <InfoButton userContext={userContext} rack={rack} />

        {/* Torch Button - only show when video is active */}
        {isVideoActive && (
          <button
            onClick={toggleTorch}
            className={`button is-small ${isTorchOn ? 'is-warning' : 'is-light'}`}
            disabled={!inventoryStarted || !torchCapability}
          >
            <span className="icon">
              <i className={`${isTorchOn ? 'fas' : 'far'} fa-lightbulb`}></i>
            </span>
          </button>
        )}

        {/* Start/Stop Button */}
        <button
          onClick={!inventoryStarted ? handleStartInventory : handleStopInventory}
          className={`button is-small ${!inventoryStarted ? 'is-success' : 'is-danger'}`}
        >
          <span className="icon">
            <i className={`fas ${!inventoryStarted ? 'fa-play' : 'fa-stop'}`}></i>
          </span>
          <span>{!inventoryStarted ? 'Start' : 'Stop'}</span>
        </button>

        {/* Missing Product Button - avec gestion de l'état disabled */}
        <button 
          className="button is-small is-warning"
          onClick={() => {
            setSelectedShelfForMissing(null);
            setShowMissingProductModal(true);
          }}
          disabled={!inventoryStarted && !lastActiveSessionId}
          title={!inventoryStarted && !lastActiveSessionId 
            ? "Please start an inventory session first" 
            : "Report missing product"}
        >
          <span className="icon">
            <i className="fas fa-exclamation-triangle"></i>
          </span>
          <span className="is-hidden-mobile">Missing product</span>
        </button>


      </div>

      {/* Message d'avertissement si nécessaire */}
      {!inventoryStarted && !lastActiveSessionId && (
        <div className="notification is-warning is-light has-text-centered">
          <p>
            <span className="icon">
              <i className="fas fa-info-circle"></i>
            </span>
            <span>Please start an inventory session to report missing products.</span>
          </p>
        </div>
      )}

      {/* Inventory Content */}
      {Object.keys(quantities).length === 0 && !isVideoActive ? (
        <div className="content">
          
          <div className="notification is-info is-light has-text-centered mt-4">
            <p>
              Ready to conquer your inventory? <strong>Start the session</strong> and watch the detected products magically appear! 🪄
            </p>
          </div>

          {/* Ajout de l'affichage des informations du rack */}
          <div className="mt-4">
            <RackInfoDisplay 
              userContext={userContext} 
              rack={rack} 
              className="has-background-white-bis p-4 rounded"
            />
          </div>

        </div>
      ) : (
        <div className="shelves-container mt-4">
          {sortedShelves.length === 0 ? (
            <div className="notification is-warning has-text-centered">
              <p>No products detected yet. Keep the session running!</p>
            </div>
          ) : (
            <>
              {sortedShelves.map(shelf => (
                <div key={shelf.id} className="mb-4">
                  <h3 className="title is-5 mb-2 pb-2 has-border-bottom">{shelf.name} {renderShelfMissingButton(shelf)}</h3>
                  {getSortedProducts(shelf).map(product => {
                    const key = `${shelf.id}-${product.name}`;
                    return (
                      <div key={product.id} className="field has-addons mb-2">
                        <div className="control is-expanded has-text-left">
                          <p className="is-size-6 has-text-weight-semibold mb-1">
                            {truncate(product.description || product.name, 50)}
                          </p>
                          <p className="is-size-7 has-text-secondary">{product.name}</p>
                        </div>
                        <div className="control">
                          <button 
                            className="button is-primary is-small" 
                            onClick={() => handleQuantityChange(shelf.id, product.name, -1)}
                          >-</button>
                        </div>
                        <div className="control">
                          <input 
                            className={`input is-small has-text-centered count-cell no-spin ${updatedProducts[key] ? 'is-blinking' : ''}`}
                            type="number"
                            value={quantities[key] || 0}
                            onChange={(e) => handleQuantityChange(
                              shelf.id, 
                              product.name, 
                              parseInt(e.target.value, 10) - (quantities[key] || 0)
                            )}
                          />    
                        </div>
                        <div className="control">
                          <button 
                            className="button is-primary is-small" 
                            onClick={() => handleQuantityChange(shelf.id, product.name, 1)}
                          >+</button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ))}

              {/* Send Button */}
              {sortedShelves.length > 0 && (
                <button 
                  className="button is-success is-fullwidth mt-4"
                  onClick={sendInventoryUpdate}
                >
                  <span className="icon"><i className="fas fa-paper-plane"></i></span>
                  <span>Send</span>
                </button>
              )}
            </>
          )}
        </div>
      )}

      {/* Modal pour les produits manquants */}
      <MissingProductModal
        isOpen={showMissingProductModal}
        onClose={() => setShowMissingProductModal(false)}
        shelves={rack.shelves}
        selectedShelfId={selectedShelfForMissing}
        onSubmit={handleMissingProductSubmit}
        userContext={userContext}
        canSubmit={inventoryStarted || lastActiveSessionId}
        submissionResponse={missingProductResponse} 
      />

    </div>
  );
};

export default InventorySession;